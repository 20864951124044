
.contatct-page {
  /* background-image: linear-gradient(linear-gradient(-190deg,transparent,#ab003c)); */
   /* background-size: 800% 800%; */
  color: rgb(255, 255, 255);
  font-size: 20px;
  padding: 20px 0;
  text-align: center;
  font-weight: 600;

  /* background-color: #520223; */
}

.contact-info{
  text-align: center;
}





.form-label{
  width: 200px;
}

.footer-content h1 {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 5%;
/* margin-left: 50%; */
}



p{
  margin-bottom: 0%;
}



.container-contact{
  text-align: center;
}

.footer-content .icon {
  text-align: center;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .footer-content {
    text-align: center;
  }
}