* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}
#footName {
  background-color:rgb(202, 202, 202);
  padding:2px 4px;
  border-radius: 3px ;
}
