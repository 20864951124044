#aiar {
    background-image: var(--grad);
    /* clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%); */
  }
  
  .rowContainer{
    display: flex;
    padding: 100px;
  }
  .aboutme {
    margin-top: 72px;
    z-index: 1;
    padding: 65px 0;
  }
  
  h1 {
    font-size: 3rem;
    font-weight: 700;
    margin: 0 0 4.5rem;
    padding-top: 50px;
    text-transform: uppercase;
    color: var(--s_color);
    text-align: center;
  }
  
  .aboutme-img {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
    border-radius: 10%;
    margin-top: 0px;
    object-fit:contain;
    align-items: center;
    
  }
  
  .image {
    text-align: center;
    padding-bottom: 70px;
  }
  
  .text {
    
    font-size: larger;
    width: 80%;
  
  }
  
  p {
    color: var(--s_color);
    text-align: left;
    text-align: justify;
    text-justify: inter-word;
  }
  
  @media (max-width: 950px) {
    .text {
      padding: 0 50px 50px 50px;
      font-size: larger;
    }
  }
  
  @media (max-width: 770px) {
    .image {
      padding-bottom: 50px;
    }
  
    .title {
      font-size: 40px;
    }
  
    #hide {
      display: flex;
      justify-content: center;
    }
    div p {
      margin: auto;
  
      padding: 10px;
      text-align: center;
    }
    .text {
      font-size: larger;
      padding-bottom: 50px;
    }
  }
  @media (max-width: 550px) {
    .text {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  