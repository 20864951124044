#main {
  padding-top: 20px;
  padding-bottom: 98px;
}

div.mobile {
  margin-top: 100px;
  padding-left: 40px;
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 3.2rem;
  /* color: var(--txtcolor); */
  color: white;
}

.main-title {
  font-size: 35px;
  /* color: var(--txtcolor); */
  margin-bottom: 0;
  padding-top: 0;
  text-align: left;
}
.d{
  font-weight: lighter;
  font-size: 20px;
}
.type-writer{
  font-size:25px;
  margin-top:20px;
  font-weight:bold;
  }
.mainX {
  padding-top: 10px;
  margin-top: 100px;
}
.n {
  padding-bottom: 15px;
}


.ani {
  margin-top: 30px;
  padding-left: 40px;
}

.btn {
  margin-top: 30px;
  background-color: var(--s_color);
  color: var(--p_color);
  border: 2px solid var(--p_color) !important;
  border-radius: 0px !important;
  font-size: 1.1rem;
  font-weight: 700;
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
  z-index: 1;
  transition: all 0.6s ease 0s;
  overflow: hidden;
}
.btn:hover {
  color: var(--s_color) !important;
}
.btn:before {
  background: var(--p_color);
  content: "";
  height: 1000px;
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transition: all 0.2s linear 0s;
  width: 0;
  z-index: -1;
}
.btn:hover:before {
  opacity: 1;
  width: 100%;
}

@media (max-width: 990px) {
  .ani,
  div.mobile {
    margin-top: 60px;
    margin: auto;
    text-align: center;
    padding: 10px;
  }

  .mainX {
    margin-top: 100px !important;
  }
  .main-title {
    text-align: center;
  }
}

@media (max-width: 650px) {
  div.mobile {
    font-size: 2rem;
  }

  .ani {
    margin-top: 0;
    padding-left: 0;
  }

  .n {
    padding-bottom: 30px;
  }
}

@media (max-width: 450px) {
  .d {
    font-size: 35px;
  }
}
