@font-face {
  font-family: "Agustina";
  src: local("Agustina"), url("Agustina.woff") format("woff2");
  font-weight: bold;
}

.navbarX {
  background-image: linear-gradient(-150deg, rgb(145, 99, 99), white);
  letter-spacing: 0.2rem;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items:normal;
  font-size: 12rem;
  padding-right: 5%;
  font-weight: 550;
  box-shadow: 0px 1px 20px rgba(147, 94, 94, 0.6);
  
}
.nav-logo{
  max-width: 285px;
  height: 50px;
}

div .navbar-logoX {
  text-decoration: none;
 
  justify-self: start;
  margin-left: 15px;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 5px;
}

.fontX {
  font-family: "Agustina";
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
.nav-menuX {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 85vw;
  justify-content: end;
  margin-right: 0;
  z-index: 99;
}

a {
  text-decoration: none;
}

nav .nav-itemX {
  font-size: 15px;
  padding-top: 13px;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 80px;
}

nav .nav-linksX {
  text-decoration: none;
  color: rgb(54, 1, 28);
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: transform 0.3s ease;
}

nav .nav-linksX:hover {
  text-decoration: none;
  /* background-color:  rgb(205, 51, 125); */
  color: rgb(54, 1, 28);
  border-radius: 4px;
  transition: all 0.2s ease-out;
  transform: scale(1.2);
}

.fa-bars {
  color: var(--s_color);
  padding: 0;
}

.nav-links-mobileX {
  display: none;
}

.menu-iconX {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItemsX {
    position: relative;
  }
  .navbarX {
    height: 80px;
  }
  .nav-menuX {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 99;

  }

  .nav-menuX.activeX {
    background: rgb(250, 212, 221);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 99;
    height: 90vh;
    margin-top: -45px;
    color: rgb(91, 18, 18);
 
  }

  .nav-linksX {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-linksX:hover {
  border-radius: 0;
    width: 300px;
  }

  .navbar-logoX {
    position: absolute;
    top: 5;
    left: 0;
    /* transform: rotate(-10deg); */
  }

  .menu-iconX {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 50px;
    background-color: rgb(67, 0, 0);
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-timesX {
  
    font-size: 2rem;
  }

  .nav-links-mobileX {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;

    text-decoration: none;
 
    font-size: 1.5rem;
  }

  .nav-links-mobileX:hover {
  
    transition: 250ms;
  }
  .nav-menuX.activeX {
    z-index: 99;
  }

  button {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .nav-menuX {
    height: 45vh;
  }
}
