#whatido {
  background-image: var(--grad);
  /* clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%); */
}

.image-container{
  display: flex;
  justify-content: center;
  align-items: center;

}




